<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-tabs v-model="activeTab">
           <v-tab>Synchro Excel</v-tab>
           <v-tab>Imports Fichier</v-tab>
           <v-tab>Historique / modifications</v-tab>
           <v-tab>Base de données</v-tab>
           <!-- v-tab>Controle des surfaces</v-tab> -->
           <!-- <v-tab>Import de document</v-tab> -->
        </v-tabs>
        <v-spacer></v-spacer>
        <v-btn v-if="activeTab === 3" color="success" :loading="saveProgress" @click.native="syncAll('all')" :disabled="saveProgress">
            Demarrer tout
        </v-btn>
    </v-toolbar>
<!-- Formulaire -->
    <v-layout v-if="activeTab === 0" wrap >
        <v-flex xs6 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title>
                    <v-icon>mdi-code-equal</v-icon>
                    Mise à jour des CODDT
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span class="caption">Dernier import :</span>
                <v-chip small outlineed label light color="white">
                    &nbsp; 2019/03/09
                </v-chip>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-file-input @change="getFile($event, 'coddt')" ref="coddt" v-model="form.file_coddt" show-size label="Fichier(s)"></v-file-input>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <div class="text-xs-right">
                <v-btn rounded color="success" @click="sendSynchFile('coddt')" :disabled="saveProgress">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Envoyer</span>
                </v-btn>
            </div>
            </v-card>
        </v-flex>
        <v-flex xs6 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-toolbar flat dark color="light-green" >
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-folder-multiple</v-icon></v-btn>
                    Conventions
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span class="caption">Dernier import :</span>
                <v-chip small outlineed label light color="white">
                    &nbsp; 2019/03/09
                </v-chip>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-file-input @change="getFile($event, 'convention')" ref="convention" v-model="form.file_convention" show-size label="Fichier(s)"></v-file-input>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <div class="text-xs-right">
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="sendSynchFile('convention')" :disabled="saveProgress">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Envoyer</span>
                </v-btn>
            </div>
            </v-card>
        </v-flex>
        <v-flex xs6 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-toolbar flat dark color="orange" >
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-folder-multiple</v-icon></v-btn>
                    Cahier des charges
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn dark rounded small outlineed label light color="red" @click="deleteDuplicateSpf('dedumpspecification')" :disabled="saveProgress">
                    Supprimer les doublons
                </v-btn>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-file-input @change="getFile($event, 'specification')" ref="specification" v-model="form.file_specification" show-size label="Fichier(s)"></v-file-input>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <div class="text-xs-right">
                <v-btn rounded color="success" @click="sendSynchFile('specification')" :disabled="saveProgress">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Envoyer</span>
                </v-btn>
            </div>
            </v-card>
        </v-flex>
        <v-flex xs6 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-toolbar flat dark color="teal" >
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-folder-multiple</v-icon></v-btn>
                    Mesures compensatoires
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span class="caption">Dernier import :</span>
                <v-chip small outlineed label light color="white">
                    &nbsp; 2019/03/09
                </v-chip>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-file-input @change="getFile($event, 'action')" ref="action" v-model="form.file_action" show-size label="Fichier(s)"></v-file-input>
            </v-layout>
            </v-container>
            <!-- <v-layout align-center wrap class="caption"><i>Télécharger un fichier d'exemple</i></v-layout> -->
            <v-divider class="mt-5"></v-divider>
            <div class="text-xs-right">
                <v-btn rounded color="success" @click="sendSynchFile('action')" :disabled="saveProgress">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Envoyer</span>
                </v-btn>
            </div>
            </v-card>
        </v-flex>
        <v-flex xs6 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-toolbar flat dark color="purple" >
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-folder-multiple</v-icon></v-btn>
                    Contrôles
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span class="caption">Dernier import :</span>
                <v-chip small outlineed label light color="white">
                    &nbsp; 2019/03/09
                </v-chip>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12 class="pa-2">
                    <v-text-field :error="(form.eve_year.length < 4)" prepend-icon='mdi-calendar' label="Année" v-model="form.eve_year"></v-text-field>
                </v-flex>
                <v-flex xs12 class="pa-2">
                    <v-file-input @change="getFile($event, 'controls')" ref="controls" v-model="form.file_controls" show-size label="Fichier(s)"></v-file-input>
                </v-flex>
            </v-layout>
            </v-container>
            <!-- <v-layout align-center wrap class="caption"><i>Télécharger un fichier d'exemple</i></v-layout> -->
            <v-divider class="mt-5"></v-divider>
            <div class="text-xs-right">
                <v-btn rounded color="success" @click="sendSynchFile('controls')" :disabled="(saveProgress || (form.eve_year.length < 4))">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Envoyer</span>
                </v-btn>
            </div>
            </v-card>
        </v-flex>
        <v-flex xs6 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-toolbar flat dark color="#00BCD4" >
                <v-toolbar-title>
                    <v-btn icon><v-icon>mdi-folder-multiple</v-icon></v-btn>
                    Communes
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <span class="caption">Dernier import :</span>
                <v-chip small outlineed label light color="white">&nbsp; -</v-chip>-->
            </v-toolbar>
            <v-container fluid grid-list-xl >
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <div class="text-xs-right">
                <v-btn rounded color="success" @click="syncTownsApi()" :disabled="(saveProgress)" class="mr-4">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Synchroniser (API)</span>
                </v-btn>
                <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn rounded outlined depressed text @click="modalTownUpload = true" color="orange" class="mr-2 white--text" v-on="on">
                        <v-icon dark>mdi-cloud-upload</v-icon><span class="hidden-sm-and-down"></span>Fichier
                    </v-btn>
                </template>
                <span>Injecter / modifier des communes</span>
            </v-tooltip>
            </div>
            </v-card>
        </v-flex>
        <v-flex xs12 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-alert border="top" colored-border type="info" elevation="2" >
                <v-list dense>
                    <v-subheader v-if="processInfo.error_file">
                        <v-btn rounded color="success" :disabled="false" @click="downloadError()">
                            <v-icon left dark>mdi-content-save</v-icon>
                            <span class="hidden-sm-and-down">Telecharger le rapport</span>
                        </v-btn>
                    </v-subheader>
                    <v-subheader v-else>RAPPORT</v-subheader>
                    <v-list-item-group color="primary" >
                        <v-list-item v-for="(item, i) in processInfo.error" :key="i" >
                        <!-- <v-list-item-icon> <v-icon v-text="item.icon"></v-icon> </v-list-item-icon> -->
                        <v-list-item-content>
                            <v-list-item-title v-text="item"></v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-alert>
            </v-card>
        </v-flex>
    </v-layout>
    <v-layout v-if="activeTab === 1" wrap >
        <v-flex xs6 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-toolbar flat dark color="primary" >
                <v-toolbar-title>
                    <v-icon>mdi-code-equal</v-icon>
                    Importer des documents (convention)
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span class="caption">zip, xlsx, docx etc... :</span>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-file-input @change="getFile($event, 'document')" ref="document" v-model="form.file_document" show-size label="Fichier(s)"></v-file-input>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <div class="text-xs-right">
                <v-btn rounded color="success" @click="sendSynchFile('document')" :disabled="saveProgress">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Envoyer</span>
                </v-btn>
            </div>
            </v-card>
        </v-flex>
        <v-flex xs6 style="max-width:100%"></v-flex>
        <v-flex xs6 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2" :class="{ 'blue': dragover }" 
            @drop.prevent="onDrop($event, 'documentcoddt', true)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false"> 
            <v-toolbar flat dark color="green" >
                <v-toolbar-title>
                    <v-icon>mdi-code-equal</v-icon>
                    Importer des documents CODDT V.C01
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <span class="caption">zip :</span>
            </v-toolbar>
            <v-container fluid grid-list-xl >
            <v-layout align-center wrap >
                <v-flex xs12>
                    <v-file-input ref="documentcoddt" show-size counter small-chips multiple 
                        @change="getFile($event, 'documentcoddt', true)" @click:clear="clearFileScope('documentcoddt', true)" v-model="form.file_documentcoddt" label="Fichier(s)">
                    <template v-slot:selection="{ text, index }">
                        <v-chip small label @click:close="removeChipFile('documentcoddt', index)" close
                            :color="form.file_documentcoddt_color[index]">
                            {{ text }}
                        </v-chip>
                    </template>
                    </v-file-input>
                </v-flex>
                <v-flex xs12>
                    <v-select 
                        :items="getCacheType('DOC')" item-text="typ_name" item-value="typ_id" 
                        v-model="form.typ_id" label="Type de fichier" >
                    </v-select>
                </v-flex>
            </v-layout>
            </v-container>
            <v-divider class="mt-5"></v-divider>
            <div class="text-xs-right" v-show="!saveProgress">
                <v-btn rounded color="success" @click="sendSynchFile('documentcoddt')" :disabled="saveProgress">
                    <v-icon left dark>mdi-content-save</v-icon>
                    <span class="hidden-sm-and-down">Envoyer</span>
                </v-btn>
            </div>
            <div v-show="saveProgress">
                <v-subheader>Envoie du fichier : {{processInfo.state}}</v-subheader>
                <v-progress-linear indeterminate></v-progress-linear>
            </div>
            </v-card>
        </v-flex>
    </v-layout>
    <v-layout v-if="activeTab === 2" wrap >
        <history-list></history-list>
    </v-layout>
    <v-card v-if="activeTab === 3" fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
            <v-flex xs12 style="max-width:100%" v-if="sync_error">
                <v-card class="ma-2 pa-5 elevation-2">    
                <v-alert border="top" colored-border type="info" elevation="2" >
                    <v-list dense>
                        <v-subheader>Erreur</v-subheader>
                        <v-list-item-group color="primary" >
                            <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title v-text="sync_error_message"></v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-alert>
                </v-card>
            </v-flex>
            <template v-for="(item, index) in syncRow">
            <v-flex xs10 class="pa-2" :key="index">
            <v-alert v-model="syncRow[index].display" dismissible 
                :color="syncRow[index].color" 
                border="left" elevation="2" colored-border :icon="(syncRow[index].sync) ? 'mdi-sync' : 'mdi-power-standby'" class="mb-1">
                {{ item.message }}
                <!-- <v-chip outlined color="blue">{{ locDateFormat.formatLocale('2020-01-01', 'fr') }}</v-chip> -->
                <template v-for="(itr, idxr) in item.result">
                    <span :key="idxr"><br />
                    <v-chip outlined color="green">{{itr.table}}</v-chip>
                    MC2: {{itr.source}} | Synchro: {{itr.sync}} | Insert: {{itr.insert}}</span>
                </template>
            </v-alert>
            </v-flex>
            <v-flex xs2 class="pa-2" :key="index + 'start'">
                <v-btn v-show="!sync_global" class="mt-3" color="success" :loading="saveProgress" @click.native="syncTable(syncRow[index])" :disabled="saveProgress">
                    Demarrer
                </v-btn>
            </v-flex>
            </template>
        </v-layout>
    </v-card>
    <v-layout v-if="activeTab === 4" wrap >
        <v-flex xs6 style="max-width:100%">
            <v-card class="ma-2 pa-5 elevation-2">    
            <v-container fluid class="pa-0 ma-0">
            <v-alert class="ma-5" border="right" colored-border type="error" elevation="2">
                Merci de contacter l’administrateur pour plus d’information.
            </v-alert>
            </v-container>
            </v-card>
        </v-flex>
    </v-layout>
    <v-dialog v-model="modalTownUpload" max-width="900" >
        <v-card color="grey lighten-4" flat>
            <v-toolbar color="primary" dark class="elevation-2" height="50px">
                <v-icon class="mr-3">mdi-chevron-down</v-icon>
                <v-toolbar-title>Importer des communes</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="" @click.native="modalTownUpload = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-layout row wrap>
                <v-flex xs6 class="pa-2">
                    <v-file-input ref="townxls" color="orange"
                        @change="getFile($event, 'townxls')" label="Selectionner un fichier"
                        v-model="form.file_townxls">
                    </v-file-input>
                </v-flex>
                <v-flex xs4 class="pa-2">

                </v-flex>
                <v-flex xs2 class="pa-2">
                    <v-btn color="green" class="mt-3" @click="sendSynchFile('townxls')" :disabled="saveProgress">Envoyer</v-btn>
                </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import history from '../pages/History.vue'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'synchro',
    props: [],
    data: function () {
        return {
            loading: { saveProgress: false },
            saveProgress: false,         // Enregistrement en cours...
            sync_progress: 0,
            sync_global  : 0,
            sync_complete: false,
            sync_error: false,
            sync_error_message: '',
            locDateFormat: dateLocale, // Format de date
            activeTab: 0,
            processInfo: { error : [ 'Information sur le traitement...' ], state: ''},
            form: { 
                file_specification: null, file_specification_name: '', file_specification_color: '', file_specification_source: '',
                file_coddt: null,         file_coddt_name: '',         file_coddt_color: '',         file_coddt_source: '',
                file_convention: null,    file_convention_name: '',    file_convention_color: '',    file_convention_source: '',
                file_action: null,        file_action_name: '',        file_action_color: '',        file_action_source: '',
                file_document: null,      file_document_name: '',      file_document_color: '',      file_document_source: '',
                file_townxls: null,       file_townxls_name: '',       file_townxls_color: '',       file_townxls_source: '',
                file_documentcoddt: [],   file_documentcoddt_name: [], file_documentcoddt_color: [], file_documentcoddt_source: '',
                typ_id: 'DOC13',
                eve_year: ''
            },
            syncRow : [
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/watershed', message: 'Bassin versant.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/town', message: 'Villes / pra / dept.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/habitat', message: 'Habitats.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/watermass', message: 'Masse d\'eau.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/speciesgroup', message: 'Groupe d\'espèces.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/species', message: 'Espèces.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/address', message: 'Adresses.' },
                //{ display: true sync: false,, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/sites', message: 'Fiches / sites.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/trackingsheet', message: 'Fiches.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all', 'coddt'], endPoint: '/synchro/trackingsheetlink', message: 'Fiches lien.' },

                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/contacts', message: 'Contacts / sites.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all', 'coddt'], endPoint: '/synchro/trackingstate', message: 'Fiches/Etat.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/trackingstatenotice', message: 'Fiches/Avis.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all', 'coddt'], endPoint: '/synchro/diagnostic', message: 'Dignostic.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/compensationsar', message: 'Compensation PRA.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all', 'coddt'], endPoint: '/synchro/compensationspe', message: 'Compensation SPE.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/compensationspe/useless', message: 'Compensation SPE supprimer lignes à 0, initialisation des dates de validation' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all', 'coddt'], endPoint: '/synchro/compensationhab', message: 'Compensation HAB.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all', 'coddt'], endPoint: '/synchro/compensationcwa', message: 'Compensation WAS.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/compensationdoc', message: 'Compensation DOC.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all', 'coddt'], endPoint: '/synchro/compensationzon', message: 'Compensation ZON.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all', 'coddt'], endPoint: '/synchro/compensationplo', message: 'Compensation PLO.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/compensationwrk', message: 'Compensation Travaux.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all', 'coddt'], endPoint: '/synchro/compensationhde', message: 'Compensation Sites.' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all', 'coddt'], endPoint: '/synchro/compensationini', message: 'Impact initial.' },

                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/trackingarea', message: 'Calcul surface.' },

                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/ganactesnotaries', message: 'FONCEA Actes notariés' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/synchro/ganparcellessig', message: 'FONCEA Parcelles' },
            ],
            dragover: false,
            modalTownUpload: false,
        }
    },
    computed: {
        progress () {
            return Math.min(100, this.form.sync_progress.length * 12.5)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.sync_progress / 50)]
        },
        validUplodTown(){
            if( this.form.file_townxls_name.length > 0 ){
                return true
            } else {
                return false
            }
        }
    },
    mounted:function (){

    },
    methods: {
        async syncAll (scope) {
            this.sync_global = true
            for (let index = 0; index < this.syncRow.length; index++) {
                if( this.syncRow[index]['scope'].includes(scope) ){
                    this.syncRow[index]['count'] = 0;
                    await this.syncTable(this.syncRow[index])
                }
            }
            this.sync_global = false
        },
        syncTable (obj) {
            // Enregistrement du formulaire
            this.sync_complete= false
            this.saveProgress = true
            obj['color']  = 'amber'
            obj['sync']   = true
            return new Promise(resolve => {
                this.$http.get( obj.endPoint ).then( (response) => {
                    this.$root.$toast({ color: 'success', text: 'Synchro terminée' })
                        this.saveProgress = false
                        this.sync_error   = false
                        obj['color']  = 'success'
                        obj['sync']   = false
                        obj['result'] = response.data.data
                        obj['count']  = obj['count'] || 0
                        for( let prop in obj['result'] ){
                            this.sync_complete= true
                            if( (obj['result'][prop].source > 0 && obj['result'][prop].insert > 0 && obj['result'][prop].source > obj['result'][prop].sync) || (obj['result'][prop].sync == 0 && obj['result'][prop].insert == 0) ){
                                this.sync_complete = false
                                obj['count']       += 1
                                break
                            }
                        }
                        if( obj['count'] > 9 ){
                            this.sync_complete = true
                        }
                        if( this.sync_complete ){
                            this.saveProgress = false
                            resolve('resolved')
                        } else {
                            resolve( this.syncTable( obj ) )
                        }
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    if( error.response.data !== undefined && error.response.data.message !== undefined ){
                        this.sync_error    = true
                        this.sync_error_message = error.response.data.message
                    }
                    obj['color'] = 'red'
                    obj['sync']   = false
                    setTimeout(() => { resolve('failed'); }, 2000)
                    this.saveProgress = false
                })
            })
        },
        clearFileScope(scope, multiple){
            this.form['file_' + scope            ] = (multiple) ? [] : ''
            this.form['file_' + scope + '_name'  ] = (multiple) ? [] : ''
            this.form['file_' + scope + '_color' ] = (multiple) ? [] : ''
            this.form['file_' + scope + '_source'] = ''
        },
        async getFile(file, scope, multiple){
            multiple = ( multiple === true ) ? true : false
            if( multiple ){

                //this.form['file_' + scope + '_color'] = []
                this.form['file_' + scope + '_name'] = []
                for (const element of file) {
                    let nameTab = element.name.split(".")
                    this.form['file_' + scope + '_name'].push(nameTab[0])

                    if( scope == 'documentcoddt' ){
                        let check = await this.checkCoddtFile(scope, nameTab[0])
                        let color = (check) ? 'primary' : 'red'
                        this.form['file_' + scope + '_color'].push( color )
                    }
                }
            } else {
                let nameTab = file.name.split(".")
                this.form['file_documentcoddt_color'] = nameTab[0]
                this.form['file_documentcoddt_name'] = nameTab[0]
            }
        },
        checkCoddtFile(scope, filename) {
            return new Promise( (resolve, reject) => {
                this.$http.post( '/synchro/' + scope + '/check', { filename: filename, typ_id:this.form.typ_id } ).then( (response) => {
                    if(response.data['file_document']['sync']){
                        resolve(1)
                    } else {
                        resolve(0)
                    }

                }).catch( () => {
                    reject(0)
                })
            })
        },
        async sendSynchFile(scope){
            if( Array.isArray(this.form['file_' + scope]) ){
                for ( const file of this.form['file_' + scope] ) {
                    this.$root.$toast({ color: 'success', text: 'envoie de ' + file.name })

                    await this.sendFiles(scope, file)

                }
            } else if( this.form['file_' + scope] ) {
                await this.sendFiles(scope, this.form['file_' + scope])
            }
        },
        sendFiles(scope, file) {
            return new Promise( (resolve, reject) => {
                // Enregistrement du formulaire
                this.processInfo.state = file.name
                this.saveProgress      = true
                var formFile = new FormData()
                for (var prop in this.form) {
                    if( prop == scope ){
                        formFile.append(prop, JSON.stringify( this.form[prop] ))
                    } else {
                        formFile.append(prop, this.form[prop])
                    }
                }
                
                formFile.append('file_' + scope, file, file.name)

                this.$http.post( '/synchro/' + scope + '/', formFile ).then( (response) => {
                    if( scope == 'coddt' ){
                        this.syncAll (scope)
                    }
                    this.$root.$toast({ color: 'success', text: 'Fichier envoyé !' })
                    this.saveProgress = false
                    for( let prop in response.data ){
                        if( Array.isArray( response.data[prop] ) ){
                            this.processInfo[prop] = response.data[prop]
                        } else {
                            this.processInfo[prop] = response.data[prop]
                        }
                    }
                    resolve('resolved')
                }).catch( (error) => {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                    reject(error)
                })
            })
        },
        syncTownsApi(){
            this.saveProgress      = true
            this.$http.get( '/synchro/townsapi/' ).then( (response) => {
                this.saveProgress = false
                this.$root.$toast({ 
                    color: 'success', text: 'Mise à jour terminée !' + ' Ajout: ' + response.data.data['p_town']['insert']
                    + ' MAJ: ' + response.data.data['p_town']['sync']
                })
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                this.saveProgress = false
            })

        },
        downloadError(){
            this.$http.customRequest({ method: 'get', url: 'synchro/reportfile/' + this.processInfo.error_file , responseType: 'blob' } )
            .then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 204 ){
                    this.$root.$toast({ color: 'warning', text: 'Ce fichier n\'existe pas' })
                } else {
                    this.$download(response.data, filename, response.headers['content-type'])
                }
            }).catch( (error) => {
                this.$root.$toast({ color: 'error', text: error })
            })
        },
        deleteDuplicateSpf(scope){
            this.$root.$confirm('Supprimer', 'Confirmer la suppression des doublons', { color: 'red', width: 500 }).then((confirm) => {
                if( confirm ){
                    this.saveProgress = true
                    this.$http.post( '/synchro/' + scope + '/' )
                    .then( (response) => {
                        this.$root.$toast({ color: 'success', text: 'Traitement terminé !' })
                        this.saveProgress = false
                        for( let prop in response.data ){
                            if( Array.isArray( response.data[prop] ) ){
                                this.processInfo[prop] = response.data[prop]
                            } else {
                                this.processInfo[prop] = response.data[prop]
                            }
                        }
                    }).catch( (error) => {
                        this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                        this.saveProgress = false
                    })
                }
            })
        },
        onDrop(e, scope, multiple) {
            this.dragover = false;
            e.dataTransfer.files.forEach( (element) => {
                this.form.file_documentcoddt.push(element)
                this.getFile([element], scope, multiple)
            })
        },
        removeChipFile (scope, index) {
            if (index >= 0){
                this.form['file_' + scope + '_color'].splice(index, 1)
                this.form['file_' + scope + '_name'].splice(index, 1)
                this.form['file_' + scope + ''].splice(index, 1)
            }
        },
        getCacheType: cacheGetters.getTypes,
    },
    components: {
        'history-list'   : history
    }
}
</script>